<template>
  <b-modal id="registered-agent-details-modal"
           ref="registered-agent-details-modal"
           size="lg"
           :hide-header-close="true"
           :no-close-on-esc="true"
           :no-close-on-backdrop="true"
  >
    <template v-slot:modal-title>
      <h3 class="m-0 p-0">
        Your Registered Agent Details
      </h3>
    </template>
    <template v-slot:default>
      <h4 class="pl-4 pr-4">{{state}}</h4>
    </template>
    <template v-slot:modal-footer>
      <div class="w-100 d-flex flex-column align-items-center">
        <div class="w-100 d-flex justify-content-lg-between border-bottom pb-3">
          <div class="d-flex flex-row pl-4">
            <div id="copyDetails">
              <span>{{name}}</span>
              <br>
              <span>{{line1}}</span>
              <br>
              <span>{{line2}}</span>
              <br>
              <span>{{city}}, {{stateAbbreviation}} {{postalCode}}</span>
              <br>
              <span>{{county}}</span>
              <br>
              <span>{{country}}</span>
            </div>
            <copy-icon @click="copyAddress" class="ml-2 mt-2 copyIcon"/>
          </div>
          <table class="mr-4" style="max-height: 3rem;">
            <tr>
              <td class="right-align">Start Date:</td>
              <td>{{startDate}}</td>
            </tr>
            <tr>
              <td class="right-align">End Date:</td>
              <td>{{endDate}}</td>
            </tr>
          </table>
        </div>
        <span v-if="additionalDetails" class="w-100 pt-3 pl-4 pr-4" v-html="additionalDetails" />
        <div class="mt-4 mb-2 w-100 d-flex justify-content-end">
          <secondary-button
            class="mr-2"
            button-text="Find a Pre-filled Form"
            @onclick="navigateToFormsLibrary('forms-library')"
          />
          <secondary-button
            class="mr-2"
            button-text="Help Me With My Filing"
            @onclick="navigateToSelectDomesticRegistration('stageline')"
          />
          <primary-button
            button-text="Close"
            aria-label="close registered agent details modal"
            @onclick="close"
          />
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { copyClipboardMixin } from '@/mixins/copyClipboardMixin'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import {
  completeInteraction,
} from '@/common/modules/clientInteractionLog'
import * as Cookies from 'js-cookie'

export default {
  name: 'RegisteredAgentDetailsModal',
  components: {
    CopyIcon: () => import('@images/ui/copy-icon.svg'),
    PrimaryButton: () => import('@/components/StagelineV2/shared/PrimaryButton'),
    SecondaryButton: () => import('@/components/StagelineV2/shared/SecondaryButton'),
  },
  mixins: [copyClipboardMixin, makeToastMixin],
  props: {
    interaction: {
      type: Object,
      default: null,
    },
    raService: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isNotAdminUser() {
      return sessionStorage.getItem('admin-logged-in') !== 'true' &&
        Cookies.get('admin') !== 'logged_in_as_client'
    },
    additionalDetails() {
      return this.raService.registered_agent_additional_details
    },
    city() {
      return this.raService.registered_agent_address.city
    },
    county() {
      return this.raService.registered_agent_address.county
    },
    country() {
      return this.raService.registered_agent_address.country
    },
    line1() {
      return this.raService.registered_agent_address.line1
    },
    line2() {
      return this.raService.registered_agent_address.line2
    },
    name() {
      return this.raService.registered_agent_name
    },
    postalCode() {
      return this.raService.registered_agent_address.zip_postal_code
    },
    state() {
      return this.raService.product.registered_agent?.jurisdiction?.state_province_region
    },
    stateAbbreviation() {
      return this.raService.registered_agent_address.state_province_region
    },
    startDate() {
      return this.convertUnixToDate(this.raService.start);
    },
    endDate() {
      return this.convertUnixToDate(this.raService.stop);
    },
  },
  methods: {
    close() {
      this.logCloseModalInteraction()
      this.$bvModal.hide('registered-agent-details-modal')
    },
    convertUnixToDate(unixTime) {
      const date = new Date(unixTime * 1000);
      // format leading zeros if needed
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      return `${month}/${day}/${date.getFullYear()}`;
    },
    copyAddress() {
      let textToCopy = `${this.line1}, ${this.line2}, ${this.city}, ${this.stateAbbreviation} ${this.postalCode}, ${this.country}`
      this.copyToClipboard(textToCopy, document.getElementById('copyDetails'), this.successToast, this.errorToast)
    },
    logButtonNavigationInteraction(destinationName) {
      this.completeInteraction({
        action: 'redirect',
        name: destinationName,
      })
    },
    logCloseModalInteraction() {
      this.completeInteraction({
        action: 'close',
        name: 'modal',
      })
    },
    completeInteraction(action) {
      if (this.isNotAdminUser) {
        completeInteraction({
          id: this.interaction.id,
          object_id: this.raService.id,
          object_table: 'Services',
          interaction: action,
          completed: true,
        })
      }
    },
    async navigateToSelectDomesticRegistration(destinationName) {
      this.logButtonNavigationInteraction(destinationName)
      await this.$router.push({ name: 'stageline-v2-decision-tree', params: { companyId: this.raService.company_id, redirect: 'true' } })
    },
    async navigateToFormsLibrary(destinationName) {
      this.logButtonNavigationInteraction(destinationName)
      await this.$router.push({ name: 'jurisdictionFilings', params: { companyId: this.raService.company_id, jurisdictionId: this.raService.product.registered_agent.jurisdiction_id }})
    },
  },
}
</script>
<style scoped lang="scss">
  .body-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .right-align {
    text-align: right !important;
    font-weight: bold;
  }
  .copyIcon:hover {
    cursor: pointer;
  }
</style>
