var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "registered-agent-details-modal",
    attrs: {
      id: "registered-agent-details-modal",
      size: "lg",
      "hide-header-close": true,
      "no-close-on-esc": true,
      "no-close-on-backdrop": true,
    },
    scopedSlots: _vm._u([
      {
        key: "modal-title",
        fn: function () {
          return [
            _c("h3", { staticClass: "m-0 p-0" }, [
              _vm._v("\n      Your Registered Agent Details\n    "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("h4", { staticClass: "pl-4 pr-4" }, [_vm._v(_vm._s(_vm.state))]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "w-100 d-flex flex-column align-items-center" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex justify-content-lg-between border-bottom pb-3",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row pl-4" },
                      [
                        _c("div", { attrs: { id: "copyDetails" } }, [
                          _c("span", [_vm._v(_vm._s(_vm.name))]),
                          _c("br"),
                          _c("span", [_vm._v(_vm._s(_vm.line1))]),
                          _c("br"),
                          _c("span", [_vm._v(_vm._s(_vm.line2))]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.city) +
                                ", " +
                                _vm._s(_vm.stateAbbreviation) +
                                " " +
                                _vm._s(_vm.postalCode)
                            ),
                          ]),
                          _c("br"),
                          _c("span", [_vm._v(_vm._s(_vm.county))]),
                          _c("br"),
                          _c("span", [_vm._v(_vm._s(_vm.country))]),
                        ]),
                        _c("copy-icon", {
                          staticClass: "ml-2 mt-2 copyIcon",
                          on: { click: _vm.copyAddress },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "table",
                      {
                        staticClass: "mr-4",
                        staticStyle: { "max-height": "3rem" },
                      },
                      [
                        _c("tr", [
                          _c("td", { staticClass: "right-align" }, [
                            _vm._v("Start Date:"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.startDate))]),
                        ]),
                        _c("tr", [
                          _c("td", { staticClass: "right-align" }, [
                            _vm._v("End Date:"),
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.endDate))]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm.additionalDetails
                  ? _c("span", {
                      staticClass: "w-100 pt-3 pl-4 pr-4",
                      domProps: { innerHTML: _vm._s(_vm.additionalDetails) },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mt-4 mb-2 w-100 d-flex justify-content-end" },
                  [
                    _c("secondary-button", {
                      staticClass: "mr-2",
                      attrs: { "button-text": "Find a Pre-filled Form" },
                      on: {
                        onclick: function ($event) {
                          return _vm.navigateToFormsLibrary("forms-library")
                        },
                      },
                    }),
                    _c("secondary-button", {
                      staticClass: "mr-2",
                      attrs: { "button-text": "Help Me With My Filing" },
                      on: {
                        onclick: function ($event) {
                          return _vm.navigateToSelectDomesticRegistration(
                            "stageline"
                          )
                        },
                      },
                    }),
                    _c("primary-button", {
                      attrs: {
                        "button-text": "Close",
                        "aria-label": "close registered agent details modal",
                      },
                      on: { onclick: _vm.close },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }